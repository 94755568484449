import StorageService from 'common/services/storage.js'
import ShareResource from 'common/services/resources/share.js'
import LogService from 'common/services/log.js'
import User from 'common/classes/User.js'

const TAG = 'INVITE'

export default {
	props: {
		uid: {
			type: String,
			default: ''
		},
	},
	data:
		function () {
			return {
				CONSTANTS: 
					{
						SHARE_SUCCESS : this.$gettext('Sharing has been successfully added'),
						SHARE_ERROR : this.$gettext('Failed to add sharing')
					},
				auth: null,
				matchAuths : false,  
				loading: true,
			}
		},

    created: function () {
		var _this = this;
		this.eventHub.shareInvite =
		{
			shareId: this.uid,
			email: this.$route.query.email,
			authorization: this.$route.params.uid,
			type: this.$route.query.type
		}
		LogService.consoleLogTag(TAG,'created','this.eventHub.shareInvite',this.eventHub.shareInvite);
		this.init();

	},			
	
	methods: {

		init : function()
		{
			//if the authorization is passed in the query it is a public sharing
			if(this.eventHub.shareInvite.type == 'public')	
			{
				this.launchPublicShare();
			}
			//else if the shareId is passed in the query it is an invitation to add a share
			else if(this.eventHub.shareInvite.type == 'private')	
			{
				this.inviteToAddShare();
			}
			else
			{
				this.eventHub.$emit('logout',new User());
			}
		},
		
		clickToLogout : function(bLogout)
		{
			if(bLogout)
			{
				this.eventHub.$emit('logout',new User(this.eventHub.shareInvite.email));
			}
			else
			{
				this.$router.push({ name: 'rooms'});
			}
		},

		/**
		 * Launch public share
		 */

		launchPublicShare : function()
		{
			LogService.consoleLogTag(TAG,'launchPublicShare');
			var user = new User();
			user.authorization = this.eventHub.shareInvite.authorization;
			this.eventHub.$emit('logout',user, true);

		},

		/**
		 * Invite to share a share
		 */

		inviteToAddShare : function()
		{
			LogService.consoleLogTag(TAG,'inviteToAddShare');
			this.auth = StorageService.getValue(localStorage, 'authorization');
			var _this = this;
			if(this.auth==null)
			{
				this.$router.push({ name: 'login', params: {user: {username:this.eventHub.shareInvite.email}}});
			}
			else
			{
				if(this.eventHub.shareInvite.shareId)
				{
					if(this.eventHub.user.username==this.eventHub.shareInvite.email)
					{
						ShareResource.editShare(this.eventHub.shareInvite.shareId,{"status": "ACCEPTED"}).then(
							function(response){
								_this.eventHub.$emit('displayAlert', _this.CONSTANTS.SHARE_SUCCESS, 'success');
								_this.$router.push({ name: 'rooms'});
							}).catch(
							function(e) {
								_this.eventHub.$emit('displayAlert', _this.CONSTANTS.SHARE_ERROR);
								_this.$router.push({ name: 'rooms'});
							});
						this.eventHub.shareInvite =
						{
							shareId: '',
							email: '',
							authorization: ''
						};
					}
					else
					{
						this.matchAuths = false;
						this.loading = false;
					}
				}
			}
		},
	}
}
